.viewappointments {
    display: flex;


    &__content {
        margin-top: 8.1rem;
        font-size: 1.6rem;
        flex: 4;
        flex-direction: column;

        display: flex;
        // justify-content: center;
        // align-items: center;
        color: black;
        padding-left: 2rem;
        padding-right: 2rem;


        &__filter {

            display: flex;
            width: 100%;
            justify-content: flex-end;
            padding: 1rem;

            &__imgbox {
                display: flex;
                justify-content: center;
                align-items: center;

                margin-right: 3rem;
                width: 5rem;
                height: 5rem;
                border-radius: 50%;
                background-color: rgba($color-primary, 0.8);


            }

            &__textbox {
                margin-right: 3rem;
                display: flex;
                justify-content: center;
                align-items: center;

                font-weight: bold;
                font-size: 2rem;

            }

            &__itembox {

            }

            &__img {
                width: 4rem;
                height: auto;
                
            }

            &__item {
                width: 4rem;
                height: auto;
                cursor: pointer;

                transition: all .2s ease;

                &:hover {
                    transform: translateY(-3px) scale(1.2);

                }

            }



        }

        &__empty {

            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            flex-direction: column;
            height: 70vh;

            img {
                width: 8rem;
                height: 8rem;

            }

            p {
                width: 30rem;
                margin-top: 3rem;
                font-weight: bold;
                font-size: 1.7rem;
                

            }

            
        }

        &__table {

            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;


            &__head {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                font-weight: bold;
                margin-bottom: 3px;
                background-color: rgba($color-primary, 0.8);

                p {
                    width: 16%;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    padding-top: 1rem;
                    padding-bottom: 1rem;
                }
            }

            &__itemcontainer {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                cursor: pointer;
                border-bottom: 1px solid grey;
                transition: all .2s ease;
                background-color: #ddd;
                margin-bottom: 3px;

                &:hover {
                    background-color: rgba($color-primary, 0.8);
                    color: white;
                    font-weight: 600;
                }

                &>* {
                    width: 16%;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                }
            }

            &__item {
                width: 16%;
                // width: 6rem;
                // height: 6rem;
                font-weight: 400;

                padding: 1rem 0;
                
                

                &__img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 50%;

                }

                &__rounded {
                    width: 5rem;
                    height: 5rem;
                }

                &__schedule {
                    font-weight: bold;
                    color: #ff4500;
                }

                &__btn {
                    width: 80%;
                    padding: 1rem 2.5rem;
                    text-align: center;
                    font-size: 1.2rem;
                    background-color: rgba($color-primary, 1);
                    color: white;

                    &:hover {
                        background-color: $color-secondary-light;
                    }
                }

            }
        }
    }

}