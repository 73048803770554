
// COLORS

$color-primary: #44949c;
$color-primary-light: #66a7ac;
$color-primary-dark: #025259;



$color-secondary-light: #bfb854;
$color-secondary-dark: #867e09;

$color-tertiary-light: #606186;
$color-tertiary-dark: #2b30bb;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;



$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;


// FONT
$default-font-size: 1.6rem;


// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;


@mixin clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// MEDIA QUERY MANAGER
/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/

@mixin respond($breakpoint) {

    @if $breakpoint == phone {
        @media only screen and (max-width: 37.5em) { @content }; // 600px
    }

    @if $breakpoint == tab-port {
        @media  only screen and (max-width: 56.25em) { @content };     // 900px
    }

    @if $breakpoint == tab-land {
        @media  only screen and (max-width: 75em) { @content };    // 1200px
    }

    @if $breakpoint == big-desktop {
        @media  only screen and (min-width: 112.5em) { @content };    // 1800px
    }
}


* {
    padding: 0;
    margin: 0;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    font-size: 62.5%;
}

body {
    font-family: 'Nunito';
}