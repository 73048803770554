.mk-appointments {
    min-height: 100vh;
    width: 100vw;
    margin-bottom: 6rem;
    margin-top: 8rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    color: black;


    &__info {
        margin-top: 1rem;
        width: 80%;

        display: flex;
        flex-wrap: wrap;

        &__item {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 0;
            padding: 1rem;
            width: 48%;

        }

        &__value {
            display: flex;
            font-size: 1.8rem;

            color: white;

            flex: 8;
            padding: 1rem;
            padding-left: 4rem;

            margin-left: -1rem;

            background-color: $color-primary;

            span {
                font-weight: bold;
            }

        }

        &__label {
            flex: 1;

            display: flex;
            justify-content: center;
            align-items: center;


            padding: 1rem;

            border-radius: 4rem;
            background-color: $color-primary;



            &__img {
                width: 4rem;
                height: 4rem;
            }
        }
    }

    &__content {
        display: flex;

        flex-direction: column;
        width: 100%;
        padding: 2rem;

        &__tablecontainer {
            height: 48vh;
            flex: 1;

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            h3 {}

        }




        &__time {

            display: flex;

            width: 100%;
            padding-top: 4rem;


            &__hr {


                display: flex;
                // justify-content: center;
                align-items: center;
                flex-direction: column;
                // height: 35vh;
                flex: 1;

                // justify-content: center;
                // align-items: center;

                &__header {

                    display: flex;
                    padding: 1rem;
                    
                    padding-top: 0;
                    width: 75%;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.6rem;
                    font-weight: bolder;

                }



                &__container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;

                }

                &__item {
                    width: 25%;
                    height: 5rem;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba($color-primary, 0.4);
                    margin-right: 2rem;
                    margin-bottom: 2rem;
                    cursor: pointer;
                    transition: all .2s none;

                    &:hover {

                        background-color: $color-primary;
                    }

                }


            }

            &__min {
                display: flex;
                flex-direction: column;
                // height: 35vh;
                flex: 1;



                &__header {

                    display: flex;
                    padding: 1rem;
                    
                    padding-top: 0;
                    width: 75%;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.6rem;
                    font-weight: bolder;


                }



                &__container {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;

                }

                &__item {
                    width: 40%;
                    height: 7rem;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba($color-primary, 0.4);
                    margin-right: 2rem;
                    margin-bottom: 2rem;
                    cursor: pointer;
                    transition: all .2s none;

                    &:hover {

                        background-color: $color-primary;
                    }

                }
            }
        }

        &__view {

            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-top: 3rem;

            &__caution {

                display: flex;
                padding: 1rem;
                align-items: center;
                padding-left: 2rem;

                background-color: $color-tertiary-dark;
                color: white;
                // flex: 1;
                width: 40%;
                font-weight: bold;
                // margin-bottom: 1.5rem;


                &__icon {
                    width: 4rem;
                    height: 4rem;
                    margin-right: 2rem;
                    color: red;

                }

                span {}
            }

            &__selected {
                display: flex;
                
                padding: 1rem;
                align-items: center;
                padding-left: 2rem;
                background-color: $color-tertiary-light;

            

                width: 40%;
                font-weight: bold;
                color: white;


                &__icon {

                    width: 3rem;
                    height: 3rem;
                    margin-right: 2rem;

                }

                span {

                    font-size: 2.5rem;

                }
            }

        }


        &__table {
            width: 50%;

            height: 80%;
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            grid-template-rows: repeat(7, 1fr);

            align-items: center;
            justify-items: center;

            background-color: rgba($color-primary, 0.4);
            font-size: 1.5rem;

            &__label {
                grid-row: 1;
                font-size: 1.4rem;
                color: grey;
            }

            &__currentmonth {
                width: 50%;
                background-color: #ddd;
                display: flex;
                padding: 1rem;
                padding-left: 3rem;
                padding-right: 3rem;
                justify-content: space-between;
                align-items: center;

                &__icon {
                    width: 3rem;
                    color: black;
                    cursor: pointer;
                    transition: all .2s none;

                    &:hover {
                        color: $color-primary;
                    }
                }

                span {
                    font-weight: bold;
                }
            }

            &__item {



                &__container {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    transition: all .2s normal;

                    &:hover {

                        background-color: $color-primary;
                    }

                }

                &__weekday {
                    font-weight: bold;
                }


            }

            &__headerbox {
                display: flex;
                padding: 1rem;
                padding-left: 2rem;
                padding-top: 0;
                width: 45%;
                justify-content: flex-start;
                align-items: center;
                font-size: 1.6rem;

            }
        }

        &__btnsection {
            display: flex;
            padding: 2rem;
            justify-content: center;
            width: 80%;
            padding-top: 5rem;
        }

        &__btn {
            width: 40rem;
            margin-left: 3rem;
            background-color: $color-primary;
            color: white;

            &:hover {
                background-color: $color-secondary-light;
            }
        }
    }
}