.sendmail {
    display: flex;
    flex-direction: column;
    padding: 3rem;
    height: 100%;

    &__inputbox {

        display: flex;


    }

    &__input {
        flex: 1;
        padding: 1rem;

        &__icon {
            cursor: pointer;
            width: 4rem;
            height: auto;
            margin-left: 2rem;

        }
    }

    &__content {
        flex: 1;
        margin-top: 2rem;
        margin-bottom: 2rem;
        border: 1px solid black;
        overflow-y: scroll;

        &__item {
            display: flex;
            padding: 1rem;

            // justify-content: center;
            align-items: center;
            border-bottom: 1px dashed #ccc;

            &:last-child {
                border-bottom: none;
            }

            p {
                flex: 1;
            }
        }

        &__close {
            margin-left: 1rem;
            width: 3rem;
            height: 3rem;
            color: red;
            cursor: pointer;
        }

    }

    &__btnsection {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__btn {
        background-color: $color-primary;
        color: white;

        &:hover {
            background-color: $color-secondary-light;
        }
    }

}