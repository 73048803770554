.viewaccepted {
    background-image: linear-gradient(to right, rgba(#111, 0.8), rgba(#222, 0.8)), url('../img/backk.jpg');
    background-size: cover;
    font-size: $default-font-size;
    padding-bottom: 3rem;
    min-height: 100vh;

    &__mainview {
        display: flex;
        margin-bottom: 12rem;

        &__template {
            flex: 4;

        }

        &__taskbar {
            flex: 1;

        }
    }

}