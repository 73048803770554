.upload {
    min-height: 100vh;
    position: relative;
    background-color: #ddd;
    position: relative;
    font-size: 1.7rem;
    overflow-x: hidden;


    &__container {
        padding: 5rem;
        padding-top: 10rem;
        padding-bottom: 10rem;
        padding-right: 2rem;
       

        display: flex;
        flex-direction: column;
        

        @include respond(small-desktop) {
            margin-top: 8rem;
            padding: 2.5rem;
        }

        @include respond(tab-port) {
            padding: 1rem;
        }

        &__box {
            display: flex;
        }

    }

    

    &__guide {
        flex: 1.5;
        padding: 4rem;

        @include respond(small-desktop) {
            padding: 2rem;
        }

        @include respond(tab-land) {
            display: none;
            width: 0;
        }

        

        &__card {
            background-color: $color-white;
            padding-top: 2rem;
            padding-bottom: 2rem;
            margin-bottom: 4rem;

            



            &__head {
                padding: 1.5rem;
                font-weight: bold;
                border-bottom: 1px dashed #222;
                margin-bottom: 1.5rem;
            }

            &__item {
                cursor: pointer;
                padding: 1rem;

                display: flex;
                justify-content: flex-start;
                align-items: center;
                transition: all .2s;

                &:hover {
                    background-color: $color-tertiary-dark;
                    color: $color-white;
                }


                span {
                    font-size: 1.4rem;
                }


                &__icon {
                    margin-right: 2rem;
                }

            }

            &__nofile {
                text-transform: uppercase;
                font-size: 1.5rem;
                color: #777;
                padding: 1.5rem;
            }

        }

        



    }

    &__main {
        flex: 3.5;
        padding: 4rem;
        padding-right: 0;

        @include respond(small-desktop) {
            padding: 2rem;
        }

        display: flex;
        flex-direction: column;

        &__card {
            display: flex;
            justify-content: space-between;
            align-items: center;

            // margin-bottom: 2rem;

            background-color: #eee;
            padding: 2rem;

            border-bottom: 1.2px dashed rgba(#000, .6);
            
            @include respond(phone-large) {
                flex-direction: column;
            }


            &__imgtextbox {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                @include respond(tab-port) {
                    width: 100%;
                    
                }

            }

            &__imgbox {
                margin-right: 2rem;
                width: 15rem;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgba($color-black, 0.2);

                box-shadow: 2px 2px #000;

                @include respond(tab-port) {
                    width: 10rem;
                    
                }
            }

            &__img {
                width: 15rem;
                height: 15rem;

                @include respond(tab-port) {
                    width: 10rem;
                    height: 10rem;
                }

            }

            &__textbox {
                height: 14rem;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: stretch;
                margin-left: 1rem;

                &__item {

                    &--name {
                        font-weight: bold;
                        font-size: 2rem;
                        // text-transform: capitalize;

                    }

                    &--type {
                        font-style: italic;

                    }

                    &--priority {
                        color: red;
                        font-weight: 600;
                        font-size: 1.5rem;
                    }

                    &--dimensions {
                        font-size: 1.5rem;
                        color: $color-secondary-dark;

                    }

                    &--size {
                        font-weight: 600;
                        color: $color-tertiary-dark;

                    }

                }

            }

            &__btncontainer {}

            &__btn {
                background-color: $color-tertiary-dark;
                color: $color-white;
                border-radius: 0;

                @include respond(tab-port) {
                    width: auto;
                }

                @include respond(phone-large) {
                    margin-top: 2.5rem;
                }

            }

            &__input {
                display: none;
                // width: 0;
            }


        }




    }

    &__submitcontainer {
        margin-top: 1rem;
        width: 50%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        align-self: center;

        @include respond(small-desktop) {
            margin-bottom: 8rem;
        }

        @include respond(phone-large) {
            margin-bottom: 14rem;
        }
        
    }

    &__btn {

        background-color: $color-tertiary-dark;
        color: white;
        
        

        &__disabled {
            background-color: gray;
            cursor: not-allowed;


            &:hover {
                background-color: gray;

            }
        }
    }
}