.accepted {
    flex: 4;
    margin-top: 8.1rem;

    &__box {
        display: flex;
        
        

        position: relative;
    }


    // padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #333;
    min-height: 100vh;

    h1 {
        font-size: 2.2rem;
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
       
        background-color: rgba(255, 255, 255, 0.3);
        padding: 2rem;
    }

    table {
        width: 100%;
        table-layout: fixed;
    }

    .tbl-header {
        background-color: rgba(255, 255, 255, 0.3);
    }

    .tbl-content {
        height: 80%;
        overflow-x: auto;
        margin-top: 0px;
        border: 1px solid rgba(255, 255, 255, 0.3);
    }

    th {
       
        padding: 15px 10px;
        text-align: left;
        font-weight: bold;
        font-size: 12px;
        color: #fff;
        text-transform: uppercase;
        transition: all .2s ease;
        cursor: pointer;

        &:hover {
            background-color: $color-primary;

        }
    }

    td {
        padding: 15px;
        text-align: left;
        vertical-align: middle;
        font-weight: 300;
        font-size: 12px;
        color: #fff;
        border-bottom: solid 1px rgba(255, 255, 255, 0.1);
    }

    thead {
    
        
    }

    tr {
       
      
    }

    .table_row {
        transition: all .2s ease;

        &:hover {
            background-color: #dc143c;
        }
    }


    &__head {
        text-align: center !important;
        border: 1px solid #eee;
        
        border-left: none;
       
      
       vertical-align: middle;

      

        &__icon {
            margin-left: 1rem;

            
        }
    }

    &__headbox {
        
    }


    /* for custom scrollbar for webkit browser*/

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    ::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }


}