.landing {
    height: 100vh;
    width: 100vw;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #222;

    &__cardbox {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

    }


    &__card {
        flex: 1;
        color: black;
        position: relative;

        // border: 2px double black;

        &__title {
            font-size: 3rem;
            margin-bottom: 6rem;
            font-weight: bolder;
            color: #00b7eb;
            
        }
    }


    &__slider {
        flex: 1;
        height: 100vh;

        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #008b8b;
        
    }

    &__input {
        width: 70%;
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;

        input {
            padding: 2rem 3rem;
            width: 100%;
            
            margin-bottom: 4rem;

            &:active {
                outline: none;
            }

            &:focus {
                outline: none;
            }
        }

        &__icon {
            width: 3rem;
            height: 3rem;

            position: absolute;
            top: -1.5rem;
            right: 0;

        }

    }

    &__btn {
        margin-top: 3rem;

        button {
            font-family: inherit;
            padding: 1rem 8rem;
            font-size: 1.5rem;
            border: none;
            background-color: #00b7eb;
            color: white;
            font-weight: 900;
            cursor: pointer;
            transition: all .2s;
            box-shadow: 1rem 1rem rgba($color: #ccc, $alpha: 0.3);

            &:hover {
                background-color: #3cb371;
                transform: translateY(-2px);
            }
        }
    }
}