.dashboard {
    // min-height: 90vh;
    background-color: #222;
    font-size: 1.8rem;
    // background-image: url(../img/airplane-2322303_1920.jpg);


    &__main {
        display: flex;

        flex: 1;
    }

    &__sidebar {
        flex: 1;
    }

    &__container {
        flex: 4;
        margin-top: 8rem;


        padding-top: 2rem;
        padding-bottom: 1rem;
        

        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;

        &__chart {
            padding: 3rem;

            display: flex;
            justify-content: center;
            align-items: center;
        }

    }

    &__btnContainer {
        width: 50%;

        display: flex;
        justify-content: space-around;
        align-items: center;

        margin-top: 4rem;
    }

    table {
        width: 100%;
        table-layout: fixed;
        
    }

    .table__container {
        width: 70%;
        
        padding: 4rem;
        background-color: #000;
    }

    .tbl-header {
        background-color: rgba(255, 255, 255, 0.3);
    }

    .tbl-content {
        height: 100%;
        overflow-x: auto;
        margin-top: 0px;
        border: 1px solid rgba(255, 255, 255, 0.3);
    }

    th {
       
        padding: 15px 10px;
        text-align: left;
        font-weight: bold;
        font-size: 18px;
        color: #fff;
        text-transform: uppercase;
    }

    td {
        padding: 17px;
        text-align: left;
        vertical-align: middle;
        font-weight: 300;
        font-size: 14px;
        color: #fff;
        border-bottom: solid 1px rgba(255, 255, 255, 0.1);
    }

    thead {
    
        
    }

    tr {
       
      
    }

    .table_row {
        transition: all .2s ease;

        &:hover {
            background-color: #dc143c;
        }
    }
}