.notif {
    background-image: url(../img/backk.jpg);
    background-size: cover;
    margin-top: 8rem;
    display: flex;
    height: 90vh;

    &__body {
        flex: 3;
        height: 90%;

        display: flex;
        justify-content: center;
        align-items: center;

    }

    &__textarea {

        text-align: center;
        
        font-size: 1.7rem;
        padding: 20rem 5rem;
        
    }

    &__taskbar {
        font-size: 1.8rem;
        flex: 1;

        &__item {
            margin-top: 3rem;
            height: 100%;
        }
    }
}