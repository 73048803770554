.viewapp {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    padding-top: 0;
    padding-bottom: 0;
    overflow-x: hidden;
    position: relative;
    


    font-size: 1.5rem;

    background-image: linear-gradient(to right, rgba(#111, 0.8), rgba(#222, 0.8)), url('../img/backk.jpg');

    &__mainview {
        display: flex;
        margin-bottom: 12rem;

        &__template {
            flex: 4;

        }

        &__taskbar {
            flex: 1;

        }
    }



    &__container {
        display: flex;
        width: 100%;
        color: $color-black;
        justify-content: center;
        align-items: center;
    }

    &__card {
        
        
        width: 90%;
        min-height: 100vh;
        padding: 4rem;
        padding-top: 1rem;
        

        box-shadow: 1rem 1rem rgba(#eee, 0.6);
        background-color: #eee;

        margin-top: 12rem;
        margin-left: 4rem;
    }


    &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 1rem;

        border-bottom: 4px solid #b0bf1a;

        &__logobox {
            display: flex;
            justify-content: flex-start;
            align-items: center;

        }

        &__img {
            width: 5rem;
            height: 5rem;
        }

        &__sidebox {
            font-size: 1.2rem;

        }

        &__text {}

        &__price {
            font-weight: bolder;

        }
    }

    &__barcode {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 25rem;
        margin-bottom: 1rem;


        &__img {

            width: 25rem;
            height: 3rem;

        }

        &__id {
            font-size: 1.2rem;

        }
    }

    &__top {

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem;
        font-size: 3rem;
        font-weight: bolder;
        margin-bottom: 4px;

        &__labels {
            flex: 1;

            display: flex;
            flex-direction: column;


            &__item {}
        }

        &__heading {
            flex: 3;
            padding: 1rem;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;


            &__main {
                width: 100%;
                font-weight: bold;
                font-size: 2rem;
                text-decoration: underline;
                text-transform: uppercase;
                text-align: center;
                word-spacing: .5rem;

            }

            &__sub {
                font-size: 1.5rem;
                width: 90%;
                text-align: center;

            }
        }

        &__passport {
            flex: 1;

            border: 1px solid #000;
            display: flex;
            justify-content: center;
            align-items: center;

            height: 12rem;
        }


    }

    &__section {

        &__container {}

        &__head {
            padding: .5rem;
            background-color: #aaa;

        }

        &__box {
            display: flex;
            flex-wrap: wrap;

        }

    }

    &__col14 {
        width: 25%;
        height: 6rem;
        padding: 2px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        border: 1px solid #000;

        &__headbox {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }

        &__head {
            text-transform: capitalize;

        }

        &__info {

            font-weight: bolder;

        }

        &__required {
            font-weight: bold;
            font-size: 1rem;
        }

    }

    &__col13 {
        width: 33.33%;

        &__box {
            width: 100%;
            display: flex;


            &>* {
                flex: 1;

                display: flex;

                align-items: flex-end;
            }
        }

        &__info {
            height: 7rem;
            font-weight: bolder;
        }

        &__container {
            width: 100%;
            padding: 2px;
            border: 1px solid #000;
            border-top: none;
        }
    }

    &__col12 {
        width: 50% !important;
    }

    &__col11 {
        width: 100% !important;
    }

    &__terms {
        display: flex;
        // justify-content: center;
        align-items: center;
        padding-top: 3px;
        padding-bottom: 3px;

        &__sign {
            display: flex;

        }

        &__input {
            display: inline;
            width: 1.5rem;
            height: 1.5rem;
            border: 2px solid #000;
            margin-right: 1rem;
        }

        &__item {
            border: 1px solid #000;
            border-bottom: none;

            font-weight: bold;

        }


    }

    &__official {
        padding: 1rem;

        border: 1px solid $color-black;
        border-top: none;
        margin-bottom: 1rem;

        &__head {
            padding: 3rem;
            padding-left: 2px;
            font-size: 2rem;
            font-weight: bold;
            text-transform: uppercase;
        }

        &__box {
            width: 100%;
            display: flex;
           
            justify-content: center;
            align-items: center;

        }


        &__list {
            flex: 2.5;
            


            &__item {
                width: 100%;
                display: flex;

                justify-content: space-between;
                align-items: center;
                margin-bottom: 1rem;

                &__label {
                    flex: 1;

                }

                &__box {
                    flex: 1.5;
                    height: 3rem;


                    border: 1px solid #000;
                    font-weight: bold;
                    

                    display: flex;
                    justify-content: center;
                    align-items: center;

                }
            }

        }

        &__passportbox {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;


        }

        &__passportitem {

            height: 20rem;
            width: 20rem;
            border: 1px solid #000;

            display: flex;
            justify-content: center;
            align-items: center;

        }
    }


    &__declare {
        display: flex;

        padding-top: 3px;
        padding-bottom: 3px;

        border-top: 1px solid #000;
        border-bottom: 1px solid #000;

        &__text {
            flex: 3;
        }

        &__sign {
            flex: 1;
            border: 1px solid #000;
            margin-left: 2rem;
        }

        &__date {
            font-weight: bold;
            margin-left: 1rem;
            margin-right: 1rem;

        }


    }

    &__footer {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;

        p {}
    }

    &__btns {
        width: 50%;
        display: flex;
        justify-content: space-around;
        margin-bottom: 12rem;
        margin-top: 8rem;
    }


}