.taskbar {
    height: 100vh;
    background-color: rgba($color-black, 0.5);
    margin-top: 12rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 2rem;


    &__item {
        &__box {
            background-color: #44949c;
            width: 80%;
            box-shadow: 2px 2px rgba($color: #fff, $alpha: 1.0);
            transition: all .2s ease;
            cursor: pointer;

            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 3rem;

            span {
                color: white;
                font-weight: bold;
    
            }

            &:hover {
                transform: translateY(-2px);
                background-color: #D00c27;
            }
        }

        &__icon {
            width: 4rem;
            height: 4rem;
        
            margin-right: 2rem;
        }

        
    }
}