.task {
    min-height: 100vh;
    background-color: #222;




    &__container {
        color: white;
        height: 80vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        align-content: center;
    }

    &__title {
        font-size: 3.5rem;
        text-transform: uppercase;
        font-weight: 700;
        display: inline-block;
        background-image: linear-gradient(to right, $color-primary-dark, $color-primary);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        letter-spacing: .2rem;
        transition: all .2s;

        width: 100%;
        text-align: center;
       
        padding-top: 4rem;
        padding-bottom: 1rem;
        &:hover {
            // transform: skewY(2deg) skewX(15deg) scale(1.1);
            text-shadow: .5rem 1rem 2rem rgba(#000, .2);
        }
    }


    &__item {
        width: 25%;
        height: 25%;
        padding: 1rem;
        margin: 3rem;
        border-radius: 5rem;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // border: 2px solid #ccc;
        background-color: rgba(#ccc, 0.3);
        cursor: pointer;
        transition: all .2s;
        text-decoration: none;

        

        &:hover {
            transform: scale(1.25);
        }

        p {
            font-size: 1.8rem;
            font-weight: 700;
            text-transform: uppercase;
            
            color: white;
            
        }


        &__icon {
            width: 6rem;
            height: 6rem;

            margin-bottom: 3rem;
        }
    }


}