.denial {
    padding: 5px;
    width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 15px;

    &__header {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;

        &__logobox {
            flex: 1;

            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

        }

        &__titlebox {
            flex: 3;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__logo {
            width: 100px;
            height: 100px;
        }

        &__title {
            flex: 4;
            font-weight: bold;
            font-size: 20px;
            color: $color-tertiary-dark;
        }
    }

    &__date {
        align-self: flex-end;
        margin-bottom: 10px;
    }

    &__salute {
        align-self: flex-start;
        margin-bottom: 10px;

    }

    &__intro {
        margin-bottom: 4px;

    }

    &__body {
        margin-bottom: 10px;

    }

    &__closure {
        align-self: flex-start;
        margin-bottom: 4px;

    }


}