.viewdocs {
    // height: 70vh;
    margin-top: 15rem;
    width: 100vw;
    margin-bottom: 8rem;

    .card {

        // FUNCTIONALITY
        perspective: 150rem;
        -moz-perspective: 150rem;
        position: relative;
        height: 45rem;
        box-shadow: 1rem 1rem $color-secondary-light;

        &__side {
            height: 45rem;
            transition: all .8s ease;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            backface-visibility: hidden;
            border-radius: 3px;
            overflow: hidden;
            box-shadow: 0 1.5rem 4rem rgba($color-black, .15);

            &--front {
                background-color: $color-white;
            }

           
        }


        &:hover &__side--front {
            // transform: rotateY(-180deg);
        }

        &:hover &__side--back {
            // transform: rotateY(0);
        }


        // FRONT SIDE STYLING
        &__picture {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            height: 30rem;
            background-blend-mode: screen;
           
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;

            &--approval {
                background-image: url(../img/confidential.png);
                background-size: 16rem 16rem;
            }

            &__pdf {
                background-size: 20rem 20rem;
            }

            &--noback {
                background-image: url(../img/nofile.png);
                background-size: 16rem 16rem;
            }
            
            /*
            &--1 {
                background-image:
                    url(../img/airline-6763903_1280.png);
            }
            */

           
        }

        &__heading {
            font-size: 2.8rem;
            font-weight: 300;
            text-transform: uppercase;
            text-align: right;
            color: $color-white;
            position: absolute;
            top: 12rem;
            right: 2rem;
            width: 75%;

        }

        &__heading-span {
            padding: 1rem 1.5rem;
            -webkit-box-decoration-break: clone;
            box-decoration-break: clone;

            
        }

        &__details {
            padding: 3rem;
            padding-top: 1.5rem;

            ul {
                list-style: none;
                width: 80%;
                margin: 0 auto;

                li {
                    text-align: center;
                    font-size: 1.5rem;
                    padding: 1rem;
                    padding-top: 0;

                    &:not(:last-child) {
                        border-bottom: 1px solid $color-grey-light-2;
                    }
                }
            }

            &__title {
                text-transform: uppercase;
                font-size: 2.5rem;
                font-weight: bolder;

            }

            &__type {

                span {
                    color: red;
                    font-weight: bold;
                }

            }

            &__download {
                margin-top: 2.5rem;
            }
        }

        // FRONT SIDE STYLING
        &__cta {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 90%;
            text-align: center;
        }

        &__price-box {
            text-align: center;
            color: $color-white;
            margin-bottom: 8rem;
        }

        &__price-only {
            font-size: 1.4rem;
            text-transform: uppercase;
        }

        &__price-value {
            font-size: 6rem;
            font-weight: 100;
        }




        @media only screen and (max-width: 56.25em),
        only screen and (hover: none) {
            height: auto;
            border-radius: 3px;
            box-shadow: 0 1.5rem 4rem rgba($color-black, .15);
            background-color: $color-white;

            &__side {
                height: auto;
                position: relative;
                box-shadow: none;

                &--back {
                    transform: rotateY(0);
                    clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 100%);
                }
            }


            &:hover &__side--front {
                transform: rotateY(0);
            }

            &__details {
                padding: 1rem 3rem;
            }

            // BACK SIDE STYLING
            &__cta {
                position: relative;
                top: 0;
                left: 0;
                transform: translate(0);
                width: 100%;
                padding: 7rem 4rem 4rem 4rem;
            }

            &__price-box {
                margin-bottom: 3rem;
            }

            &__price-value {
                font-size: 4rem;
            }
        }

        &__btn {
            font-size: 1.3rem;
            padding: 1rem 2.5rem;
            background-color: #333;
            color: $color-white;
        }
    }

    .row {
        width: 80%;
        margin: 0 auto;

        

        &:not(:last-child) {
            margin-bottom: $gutter-vertical;

            @include respond(tab-port) {
                margin-bottom: $gutter-vertical-small;
            }
        }



        @include respond(tab-port) {
            max-width: 53rem;
            padding: 0 3rem;
        }

        @include clearfix;

        [class^="col-"] {
            float: left;


            &:not(:last-child) {
                margin-right: $gutter-horizontal;

                @include respond(tab-port) {
                    margin-right: 0;
                    margin-bottom: $gutter-vertical-small;
                }
            }

            @include respond(tab-port) {
                width: 100% !important;
            }

        }

        .col-1-of-2 {
            width: calc((100% - #{$gutter-horizontal}) / 2);
        }

        .col-1-of-3 {
            width: calc((100% - 2 * #{$gutter-horizontal}) / 3);
        }

        .col-2-of-3 {
            width: calc(2 * ((100% - 2 * #{$gutter-horizontal}) / 3) + #{$gutter-horizontal});
        }

        .col-1-of-4 {
            width: calc((100% - 3 * #{$gutter-horizontal}) / 4);
        }

        .col-2-of-4 {
            width: calc(2 * ((100% - 3 * #{$gutter-horizontal}) / 4) + #{$gutter-horizontal});
        }


        .col-3-of-4 {
            width: calc(3 * ((100% - 3 * #{$gutter-horizontal}) / 4) + 2 * #{$gutter-horizontal});
        }

    }

    .custom_row {
        max-width: 80%;
        
        display: flex;
        justify-content: space-around;
        align-items: center;

        flex-wrap: wrap;
        margin-left: 7rem;

        &__box {
            width: 100%;
            display: flex;
            justify-content: center;
            // align-items: center;
            flex-direction: column;
        }




        &:not(:last-child) {
            margin-bottom: $gutter-vertical;

            @include respond(tab-port) {
                margin-bottom: $gutter-vertical-small;
            }
        }

        @include respond(tab-port) {
            max-width: 53rem;
            padding: 0 3rem;
        }

        @include clearfix;

        & > * {
            margin-right: 2rem;
            margin-bottom: 4rem;
        }
        /*

        [class^="col-"] {
            float: left;


            &:not(:last-child) {
                margin-right: $gutter-horizontal;

                @include respond(tab-port) {
                    margin-right: 0;
                    margin-bottom: $gutter-vertical-small;
                }
            }

            @include respond(tab-port) {
                width: 100% !important;
            }

        }
        */

        .col-1-of-2 {
            width: calc((100% - #{$gutter-horizontal}) / 2);
        }

        .col-1-of-3 {
            width: calc((100% - 2 * #{$gutter-horizontal}) / 3);
        }

        .col-2-of-3 {
            width: calc(2 * ((100% - 2 * #{$gutter-horizontal}) / 3) + #{$gutter-horizontal});
        }

        .col-1-of-4 {
            width: calc((100% - 3 * #{$gutter-horizontal}) / 4);
        }

        .col-2-of-4 {
            width: calc(2 * ((100% - 3 * #{$gutter-horizontal}) / 4) + #{$gutter-horizontal});
        }


        .col-3-of-4 {
            width: calc(3 * ((100% - 3 * #{$gutter-horizontal}) / 4) + 2 * #{$gutter-horizontal});
        }


    }
}
