.filterrodal {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__header {
        font-size: 2rem;
        color: $color-tertiary-light;
        margin-bottom: 3rem;

    }

    &__form {

    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        cursor: pointer;

        &:hover {
            svg {
                color: red;
            }

            p {
                color: red;
                font-weight: bold;
            }
        }

        &__icon {
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 2.5rem;
            color: green;
            transition: all .2s ease;

            display: flex;
            justify-content: center;
            align-items: center;


        }

        p {
            transition: all .2s ease;

        }
    }

    &__btnbox {
        margin-top: 3rem;
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__btn {
        border: none;
        padding: 2rem 4.5rem;
        background-color: $color-tertiary-light;
        color: $color-white;
        font-weight: bold;
        transition: all .2s ease;

        &:hover {
            background-color: $color-tertiary-dark;
        }

    }
}