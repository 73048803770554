.addnote {

    &__header {
        font-size: 2rem;
        font-weight: bold;
        color: $color-tertiary-dark;
        margin: 2rem;
    }

    &__inputbox {
        width: 100%;
    }

    &__input {
        width: 100%;
    }

    &__btnbox {
        display: flex;
        justify-content: space-around;
    }

    &__attached {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
        margin-top: 2rem;
        border: 1px solid $color-black;
        padding: 1rem;

        &__name {
            font-weight: bolder;

        }

        &__update {
            color: red;
            font-weight: 700;
            cursor: pointer;
            transition: all .2s ease;

            &:hover {
                color: $color-tertiary-dark;
            }

        }
    }

    &__btn {
        width: 20rem;
        font-weight: bold;
        font-size: 1.3rem;
        background-color: $color-tertiary-dark;
        color: white;
        &,
        &:link,
        &:visited {
            text-transform: uppercase;
            text-decoration: none;
            padding: 1.5rem 3.5rem;
            display: inline-block;
            border-radius: 10rem;
            transition: all .2s;
            position: relative;
            
    
            //Change for the <button> element
            border: none;
            cursor: pointer;
        }
        
        &:hover {
            transform: translateY(-3px);
            box-shadow: 0 1rem 2rem rgba($color-black,.2);
            background-color: $color-primary;
    
            &::after {
                transform: scaleX(1.4) scaleY(1.6);
                opacity: 0;
            }
        }
        
        &:active,
        &:focus {
            outline: none;
            transform: translateY(-1px);
            box-shadow: 0 .5rem 1rem rgba($color-black,.2);
        }
    }
}