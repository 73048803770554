.myrodal {
    padding: 2rem;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    &__iconbox {
        padding: 2rem;

    }

    &__icon {
        width: 10rem;
        height: 10rem;

    }

    &__message {
        width: 95%;
        font-weight: bold;
        text-align: center;

        span {
            font-weight: bold;
            color: red;
        }

        &__big {
            font-size: 1.5rem;
            text-transform: uppercase;

            &__center {
                text-align: center;
            }
        }
    }

    &__btnContainer {
        margin-top: 4rem;

    }

}