@keyframes bounce {
    0% {
        
    }
}

.adduser {

    min-height: 100vh;

    display: flex;
    padding: 2rem;
    padding-bottom: 3rem;
    // justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1.6rem;
    background-image: url('../img/airplane-2322303_1920.jpg');
    background-size: cover;

    &__container {
        display: flex;
        flex-direction: column;
        // height: 25rem;
        justify-content: center;
        align-items: center;
        position: fixed;
        right: 2rem;
        bottom: 6rem;
        transition: all .2s ease;
    }

    &__addbox{
        z-index: 99;
        transition: all .2s ease;
        margin-bottom: 3rem;
        

        &:hover {
            transform: translateY(-4px);
        }
    }

    &__add {
        z-index: 99;
        width: 7rem;
        height: 7rem;
        cursor: pointer;

        
    }

    &__wrap {
        flex: 1;
        width: 85%;
        margin-top: 12rem;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        // align-content: space-around;


    }


    &__item {
        width: 35rem;
        background-color: #eee;
        // padding: 2rem;
        border: 1px solid #aaa;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 5rem;
        margin-right: 5rem;
        box-shadow: 1rem 1rem 1rem 1rem rgba($color-primary-light, 0.5);

        &__container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            padding: 2rem;

        }

        &__maindiv {
            padding: 2rem;

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            &__imgdiv {
                margin-bottom: 2.5rem;

            }

            &__img {

                width: 10rem;
                height: 10rem;

            }

            &__name {
                font-weight: 1.8rem;
                font-size: 2.5rem;

            }
        }

        &__price {
            font-weight: bold;
            font-size: 2.1rem;
            color: $color-secondary-dark;
        }

        &__edit {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 100%;
            padding: 1rem;
            background-color: $color-tertiary-light;

            font-weight: bold;
            color: #fff;
            cursor: pointer;
            transition: all .2s ease;

            &:hover {
                background-color: $color-primary;
            }

        }


    }
}