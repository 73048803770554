.sidebar {
    font-size: 1.6rem;
    flex: 1;
    min-height: 100vh;
    padding: 1rem;
    margin-top: 8.1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    background-color: #222;
    color: $color-white;

    &__header {

        font-size: 1.3rem;
        display: none;
        width: 0;

    }

    &__item {
        width: 100%;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 2rem;
        cursor: pointer;

        &__imgbox {
            
        }

    }

    &__item:hover &__text {
        background-color: #dc143c;
    }

    &__item:hover &__item__imgbox &__img{
        transform: scale(1.15);
    }


    &__item:hover {}

    &__img {
        width: 4rem;
        height: 4rem;
        transform: scale(1);
        transition: all .2s ease;

    }

    &__text {
        flex: 1;
        height: 5rem;
        margin-left: 2rem;
        padding: 1rem;

        display: flex;
        justify-content: flex-start;
        align-items: center;

    }
}