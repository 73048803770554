.header {
    display: flex;
    padding: 1rem;
    padding-right: 3rem;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    background-color: #222;
    height: 8rem;
    font-size: 1.7rem;

    position: absolute;
    top: 0;
    left: 0;

    &__user {
        flex: 0 0 20%;
        color: white;

        display: flex;

        justify-content: flex-start;
        align-items: center;

        &__avatar {
            display: flex;

            justify-content: center;
            align-items: center;

            &__img {
                height: 3rem;
                width: 3rem;
                color: white;
                margin-right: 1.5rem;
            }
        }

        &__info {

            &__name {
                font-weight: bold;
                color: $color-secondary-dark;
            }

            &__date {}
        }
    }

    &__search {
        flex: 1;

        display: flex;

        justify-content: center;
        align-items: center;



        &__input {
            width: 70%;
            padding: 1.2rem;
            border-radius: 5rem;
        }

    }

    &__logout {
        flex: 0 0 20%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        cursor: pointer;

        &:hover &__text {

            color: $color-primary;
        }

        &:hover &__icon {
            color: $color-primary;

        }

        &__icon {
            height: 3rem;
            width: 3rem;
            color: white;
            transition: all .2s ease;
        }

        &__text {
            color: white;
            transition: all .2s ease;
        }
    }



}