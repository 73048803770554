
// TYPOGRAPHY

.heading-primary {
    color: $color-secondary-dark;
    text-transform: uppercase;
    backface-visibility: hidden;
    margin: 6rem;
    font-size: 3rem;

    &--main {
        display: block;
        font-size: 6rem;
        font-weight: 400;
        letter-spacing: 3.5rem;
    
        animation: MoveInLeft 1s ease-out;

        @include respond(phone) {
            font-size: 5rem;
            letter-spacing: 1rem;
        }
    }


    &--sub {
        display: block;
        font-size: 2rem;
        font-weight: 700;
        letter-spacing: 1.3rem;
    
        animation: MoveInRight 1s ease-out;

        @include respond(phone) {
            letter-spacing: .5rem;
        }
        
    }
}

.heading-secondary {
    padding-top: 2rem;
    font-size: 3.5rem;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    background-image: linear-gradient(to right, $color-primary-light, $color-primary-dark);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    letter-spacing: .2rem;
    transition: all .2s;

    @include respond(tab-port) {
        font-size: 3rem;
    }

    @include respond(phone) {
        font-size: 2.5rem;
    }

    &:hover {
        transform: skewY(2deg) skewX(15deg) scale(1.1);
        text-shadow: .5rem 1rem 2rem rgba($color-black, .2);
    }
}

.heading-tertiary {
    font-size: $default-font-size;
    font-weight: 700;
    text-transform: uppercase;
}

.paragraph {
    font-size: $default-font-size;
    &:not(:last-child) {
        margin-bottom: 3rem;
    }
}

// PAGE MARGIN CLASSES


.u-center-text { text-align: center !important; }

.u-margin-bottom-small { margin-bottom: 1.5rem !important; }
.u-margin-bottom-medium {
    margin-bottom: 4rem !important;

    @include respond(tab-port) {
        margin-bottom: 3rem !important;
    }

}

.u-margin-bottom-big { 
    margin-bottom: 8rem !important;

    @include respond(tab-port) {
        margin-bottom: 5rem !important;
    }
}

.u-no-border {border: none !important;}

.u-margin-top-big { margin-top: 8rem !important; }
.u-margin-top-huge { margin-top: 10rem !important; }
.u-no-border-right { border-right: none !important; }
.u-no-border-left { border-left: none !important; }
.u-margin-bottom { border-bottom: 1px solid #000 !important; }
.u-error-background {
    background-color: red !important;
    color: $color-white !important;
}
.u-error-border {
    border: 2px double red !important;
}