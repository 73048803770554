.safemodal {
    padding: 2rem;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    

    &__iconbox {
        
    }

    &__icon {
        width: 5rem;
    }

    &__content {
        font-size: 2.2rem;
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: bold;
        text-transform: capitalize;
    }

    &__btnsection {
        display: flex;
        width: 70%;
        justify-content: space-around;
        align-items: center;
    }

    &__btn {
        width: 40%;
        padding: 1rem 2.5rem;
        font-size: 1.4rem;

        background-color: $color-primary;
        color: white;

        &:hover {
            background-color: $color-secondary-light;
        }
        
    }
}